@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

button {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
}

input {
  background-color: transparent;
  padding: 0;
  border: 0;
  outline: 0;
}
input[type="submit"] {
  cursor: pointer;
}
input::-webkit-input-placeholder {
  font-size: 0.85rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  letter-spacing: 0.1rem;
  color: #6e6e6e;
}
input::-moz-placeholder {
  font-size: 0.85rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  letter-spacing: 0.1rem;
  color: #6e6e6e;
}
input:-ms-input-placeholder {
  font-size: 0.85rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  letter-spacing: 0.1rem;
  color: #6e6e6e;
}
input::-ms-input-placeholder {
  font-size: 0.85rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  letter-spacing: 0.1rem;
  color: #6e6e6e;
}
input::placeholder {
  font-size: 0.85rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  letter-spacing: 0.1rem;
  color: #6e6e6e;
}

@keyframes bounceLeft {
  0% {
    transform: translate3d(100%, -50%, 0);
  }
  50% {
    transform: translate3d(-30px, -50%, 0);
  }
  100% {
    transform: translate3d(0, -50%, 0);
  }
}

@keyframes bounceRight {
  0% {
    transform: translate3d(0, -50%, 0);
  }
  50% {
    transform: translate3d(calc(100% + 30px), -50%, 0);
  }
  100% {
    transform: translate3d(100%, -50%, 0);
  }
}

@keyframes showSignUp {
  100% {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

.user {
  display: flex;
  position: fixed;
  /* top: 10%; */
  /* left: 50%; */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  background-size: cover;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 100;
}

.user_options-container {
  position: relative;
  width: 70%;
}

.user_options-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.85);
  border-radius: 3px;
}

.user_options-registered,
.user_options-unregistered {
  width: 50%;
  padding: 60px 45px;
  color: #fff;
}

.user_registered-title,
.user_unregistered-title {
  margin-bottom: 15px;
  font-size: 1.66rem;
  /* line-height: 1em; */
  font-weight: 600;
  font-family: var(--font-poppins);
}

.user_unregistered-text,
.user_registered-text {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: var(--font-poppins);
}

.user_registered-login,
.user_unregistered-signup {
  margin-top: 30px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 30px;
  color: #fff;
  text-transform: uppercase;
  line-height: 1em;
  letter-spacing: 0.2rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.user_registered-login:hover,
.user_unregistered-signup:hover {
  color: rgba(34, 34, 34, 0.85);
  background-color: #ccc;
}

.user_options-forms {
  position: absolute;
  top: 50%;
  left: 40px;
  width: calc(50% - 40px);
  min-height: 125%;
  background-color: #fff;
  border-radius: 3px;
  /* box-shadow: 2px 0 15px rgba(0, 0, 0, 0.25); */
  box-shadow: 0px 0px 30px 8px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  transform: translate3d(100%, -50%, 0);
  transition: transform 0.4s ease-in-out;
}

.user_options-forms .user_forms-login {
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.user_options-forms .forms_title {
  margin-bottom: 45px;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  color: #1e40af;
  letter-spacing: 0.1rem;
}

.fileUpload {
  display: none;
}

.custom-file-upload {
  /* border-bottom: 2px solid #ccc; */
  display: inline-block;
  padding-top: 6px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: #6e6e6e;
  text-align: right;
  width: 200px;
  /* justify-content: start; */
  align-items: center;
  transition: all 500ms ease-in-out;
}

.custom-file-upload :hover {
  box-shadow: 0 0.7em 1.5em -0.5em #5e2bff;
  /* transform: scale(1.02); */
}

.cssbuttons-io-button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0.3rem 0.9rem;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg, #5e2bff 0%, #5e2bff 100%);
  border: none;

  letter-spacing: 0.05em;
  border-radius: 20em;
}

.cssbuttons-io-button svg {
  margin-right: 8px;
}

.cssbuttons-io-button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em rgb(149, 91, 255);
}

.cssbuttons-io-button:active {
  box-shadow: 0 0.3em 1em -0.5em rgb(160, 109, 255);
}

.member-forms_title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  color: #1e40af;
  letter-spacing: 0.1rem;
}

.user_options-forms .forms_field:not(:last-of-type) {
  margin-bottom: 10px;
}

.user_options-forms .forms_field-input {
  width: 100%;
  border-bottom: 2px solid #ccc;
  padding: 6px 20px 6px 6px;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  font-size: 1rem;
  /* font-weight: 300; */
  color: black;
  font-weight: 500;
  letter-spacing: 0.1rem;
  transition: border-color 0.2s ease-in-out;
}

.user_options-forms .forms_field-input:focus {
  border-color: rgb(92, 97, 137);
}

.user_options-forms .forms_buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.user_options-forms .forms_buttons-forgot {
  font-family: var(--font-poppins);
  letter-spacing: 0.1rem;
  color: rgb(77, 76, 76);
  font-weight: 600;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

.user_options-forms .forms_buttons-forgot:hover {
  color: #858383;
}

.user_options-forms .forms_buttons-action,.forget_pass_button {
  background-color: #1e40af;
  border-radius: 3px;
  padding: 10px 35px;
  font-size: 1rem;
  /* font-family: "Montserrat", sans-serif; */
  font-family: var(--font-poppins);
  /* font-weight: 300; */
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  transition: background-color 0.2s ease-in-out;
}

.user_options-forms .forms_buttons-action:hover {
  background-color: #2e55d4;
}

.forget_pass_button:hover {
  background-color: #2e55d4;
}

.user_options-forms .user_forms-signup,
.user_options-forms .user_forms-login {
  position: absolute;
  top: 60px;
  left: 40px;
  width: calc(100% - 80px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out,
    transform 0.5s ease-in-out;
}

.user_forms-login {
  top: 70px !important;
  left: 40px;
}

.user_options-forms .user_forms-signup {
  transform: translate3d(120px, 0, 0);
}

.user_options-forms .user_forms-signup .forms_buttons {
  justify-content: flex-end;
}

.user_options-forms .user_forms-login {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.user_options-forms.bounceLeft {
  animation: bounceLeft 1s forwards;
}

.user_options-forms.bounceLeft .user_forms-signup {
  animation: showSignUp 1s forwards;
}

.user_options-forms.bounceLeft .user_forms-login {
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-120px, 0, 0);
}

.user_options-forms.bounceRight {
  animation: bounceRight 1s forwards;
}

@media screen and (max-width: 990px) {
  .user_options-forms {
    min-height: 350px;
  }

  .user_options-forms .forms_buttons {
    flex-direction: column;
  }

  .user_options-forms .user_forms-login .forms_buttons-action {
    margin-top: 30px;
  }

  .user_options-forms .user_forms-signup,
  .user_options-forms .user_forms-login {
    top: 40px;
  }

  .user_options-registered,
  .user_options-unregistered {
    padding: 50px 45px;
  }
}
