.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #a0aec0;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #718096;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #a0aec0 #f1f1f1;
}


/* social icon css */




.social-icon {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}
.instagram-icon {
  width: 65px;
  height: 65px;
}

.snapchat-icon {
  height: 68px;
  width: 68px;
}

.social-icon:hover .social-icon-bloom {
  -webkit-animation: background 0.5s ease both;
  animation: background 0.5s ease both;
}

.social-icon:hover .social-icon-sparkle-line::before {
  -webkit-animation: line 1s ease both;
  animation: line 1s ease both;
}

.social-icon:hover .social-icon-shape {
  -webkit-animation: shapeAnimation 1s ease both;
  animation: shapeAnimation 1s ease both;
}

.social-icon-bloom {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border-width: 0;
  border-style: solid;
  will-change: border-width;
  border-color: #dee0e0;
}

.social-icon-sparkle-line {
  position: absolute;
  width: 100%;
  height: 100%;
}
.social-icon-sparkle-line::before {
  position: absolute;
  z-index: 1;
  top: 30%;
  width: 4px;
  height: 0;
  left: calc(50% - 2px);
  border-radius: 2px;
  will-change: top, height;
  content: "";
}
.social-icon-sparkle-line:nth-child(1) {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.social-icon-sparkle-line:nth-child(2) {
  -webkit-transform: rotate(110deg);
  transform: rotate(110deg);
}
.social-icon-sparkle-line:nth-child(3) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.social-icon-sparkle-line:nth-child(4) {
  -webkit-transform: rotate(250deg);
  transform: rotate(250deg);
}
.social-icon-sparkle-line:nth-child(5) {
  -webkit-transform: rotate(320deg);
  transform: rotate(320deg);
}

.social-icon-shape {
  z-index: 1;
  padding: 18px;
  width: 100%;
  height: 100%;
  fill: #0b1a1a;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

/* Twitter */
.twitter-icon .social-icon-bloom {
  border-color: #191d1f;
}
.twitter-icon .social-icon-sparkle-line::before {
  background: #191d1f;
}
.twitter-icon:hover .social-icon-shape {
  fill: #191d1f;
}

/* Twitter */
.facebook-icon .social-icon-bloom {
  border-color: #3b5998;
}
.facebook-icon .social-icon-sparkle-line::before {
  background: #3b5998;
}
.facebook-icon:hover .social-icon-shape {
  fill: #3b5998;
}

/* Instagram */
.instagram-icon .social-icon-bloom {
  border-color: #E1306C;
}
.instagram-icon .social-icon-sparkle-line::before {
  background: #E1306C;
}
.instagram-icon:hover .social-icon-shape {
  fill: #E1306C;
}

/* Snapchat */
.snapchat-icon .social-icon-bloom {
  border-color: #D44638;
}
.snapchat-icon .social-icon-sparkle-line::before {
  background: #D44638;
}
.snapchat-icon:hover .social-icon-shape {
  fill: #D44638;
}

@-webkit-keyframes line {
  0% {
    top: 30%;
    height: 0;
  }
  40% {
    opacity: 1;
    height: 14px;
  }
  100% {
    opacity: 0;
    top: 10%;
    height: 0;
  }
}

@keyframes line {
  0% {
    top: 30%;
    height: 0;
  }
  40% {
    opacity: 1;
    height: 14px;
  }
  100% {
    opacity: 0;
    top: 10%;
    height: 0;
  }
}
@-webkit-keyframes shapeAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  60% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes shapeAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  30% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  60% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes background {
  0% {
    border-width: 40px; /* half icon width */
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    border-width: 0;
  }
}
@keyframes background {
  0% {
    border-width: 40px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    border-width: 0;
  }
}


/* fixed button css */

/* From Uiverse.io by vinodjangid07 */ 
.button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button:hover {
  width: 120px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(68, 61, 91);
  align-items: center;
}

.button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}



.viewDetails-Btn {
  /* padding: 8px 8px; */
  border: unset;
  /* border-radius: calc(var(--radius) - 2px); */
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
 }
 
 .viewDetails-Btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: calc(var(--radius) - 2px);
  background-color: #1D4ED8;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
 }
 
 .viewDetails-Btn:hover {
  color: #e8e8e8;
 }
 
 .viewDetails-Btn:hover::before {
  width: 100%;
 }


 /* From Uiverse.io by vinodjangid07 */ 
.BtnLogout {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}

/* plus sign */
.sign {
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}
/* text */
.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1em;
  font-weight: 600;
  transition: all 0.3s ease;
}
/* hover effect on button width */
.BtnLogout:hover {
  width: 115px;
  border-radius: 40px;
  transition-duration: .3s;
}

.BtnLogout:hover .sign {
  width: 40%;
  /* transition-duration: .3s ; */
  transition: all 0.3s ease;
  padding-left: 10px;
}
/* hover effect button's text */
.BtnLogout:hover .text {
  opacity: 1;
  width: 60%;
  transition: all 0.3s ease;
  padding-right: 10px;
}
/* button click effect*/
.BtnLogout:active {
  transform: translate(3px ,3px);
}


.tabButtons {
  position: relative;
  padding: 5px 15px;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 50px;
  overflow: hidden;
  z-index: 1;
  transition: all 0.1s ease-in-out;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.tabButtons:hover {
  transform: scale(1.03);
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.tabButtons:active {
  transform: scale(0.9);
}

.tabButtons::before {
  content: "";
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #007bff, #00bfff);
  transition: all 0.2s ease-in-out;
  z-index: -1;
  border-radius: 50px;
}

.tabButtons:hover::before {
  right: 0;
}


.btn-12{
  position: relative;
  border:none;
  box-shadow: none;
  width: 130px;
  height: 40px;
  line-height: 42px;
  -webkit-perspective: 230px;
  perspective: 230px;
}
.btn-12 span {
  background: rgb(0,172,238);
  background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
  display: block;
  position: absolute;
  width: 130px;
  font-size: 1.25rem; /* Increased from 1rem to 1.25rem */
  height: 40px;
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.2),
   4px 4px 5px 0px rgba(0,0,0,.2);
  border-radius: 5px;
  margin:0;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.btn-12 span:nth-child(1) {
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12 span:nth-child(2) {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 50% -20px;
  -moz-transform-origin: 50% 50% -20px;
  transform-origin: 50% 50% -20px;
}

.btn-12:hover span:nth-child(1) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.btn-12:hover span:nth-child(2) {
  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
 color: transparent;
  -webkit-transform: rotateX(-90deg);
  -moz-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}

.bg-grid-slate-100 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23f1f5f9' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.animate-in {
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}