@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background-color: rgb(246, 246, 247);
  padding: 0.5px 4%;
  padding-top: 15px;
  padding-bottom: 15px;
  position: sticky;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  top: 0;
  transition: all 0.3s ease;
}

.logo {
  height: 55px;
  width: 55px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.navList {
  display: flex;
  align-items: center;
  gap: 35px;
  flex: 1;
  justify-content: center;
  text-decoration: none;
}

.navList li {
  list-style: none;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: var(--font-poppins);
  transition: all ease-in-out 0.2s;
  text-decoration: none;
}

.navList li a {
  position: relative;
  transition: all 0.3s ease;
}

.navList li:hover {
  transform: translateY(-3px);
}

.navList li a::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #4f37a7;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.3s ease;
}

.navList li a:hover::before {
  transform: scaleY(1);
}

.activeNavItem {
  color: blue; /* Change to your desired active color */
  font-weight: 700;
  /* Add any other styling you want for the active nav item */
}

.navList li a {
  text-decoration: none;
}

.navRight {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navRight li {
  list-style: none;
  font-family: var(--font-poppins);
  color: #000;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.mobileLoginBtn {
  background-color: #5e2bff;
  width: 90px;
  height: 40px;
  color: white;
  border-radius: 54px;
  font-size: 20px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.navRight a {
  text-decoration: none;
}

.navRight button {
  background-color: #5e2bff;
  width: 120px;
  height: 35px;
  color: white;
  border-radius: 54px;
  font-size: 17px;
  display: inline-block;
  border: none;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
}

.arrow {
  background: var(--secondary-color);
}

.navRight button:hover .arrow:before {
  right: -1px;
}

.navRight button:hover {
  background-color: #4c22ac;
}

.navList li:hover {
  color: var(--button-background);
}

.navRight li:hover {
  color: rgb(121, 121, 121);
}

@media (max-width: 938px) {
  .Navbar {
    padding: 5px 2%;
    padding-top: 10px;
    align-items: center;
  }
  .navList {
    display: none;
  }
}

@media (max-width: 480px) {
  .Navbar {
    padding: 10px 20px;
  }
  .logo {
    align-self: center;
  }
  .navRight button {
    font-size: 11px;
    width: 100px;
    height: 35px;
  }
  .navRight {
    gap: 15px;
  }
}

.Navbar.scrolled {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  /* margin: 10px 20px; */
  border-radius: 50px;
}

.Navbar.scrolled .mobileMenu {
  margin: 0 20px;
  border-radius: 0 0 20px 20px;
  width: calc(100% - 40px);
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hamburger {
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger span {
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.hamburger.open span:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.mobileMenu {
  position: fixed;
  padding: 10px 0px;
  top: 61px; 
  left: 0;
  width: 100%;
  background-color: rgba(246, 246, 247, 0.98);
  /* backdrop-filter: blur(10px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 998;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.mobileMenu.show {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 425px) {
  .mobileMenu {
    /* top: 100%; */
  }
}
@media (min-width: 768px) {
  .mobileMenu {
    /* top: 8%; */
    top: 70px;
  }
}
@keyframes smoothSlideUp {
  0% {
    opacity: 0;
    transform: scaleY(0);
    height: 0;
  }
  70% {
    opacity: 0.7;
    transform: scaleY(1.05);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
    height: auto;
  }
}

.mobileMenu > * {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInContent 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards 300ms;
}

@keyframes fadeInContent {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobileNavList {
  width: 100%;
  padding: 0;
  margin: 0;
}

.mobileNavList li {
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.3s forwards;
  animation-delay: calc(var(--item-index) * 0.1s);
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobileNavList li {
  list-style: none;
  padding: 15px 25px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.mobileNavList li::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #5e2bff;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.mobileNavList li:hover {
  background-color: rgba(94, 43, 255, 0.05);
}

.mobileNavList li:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.mobileNavList li a {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
}

/* .mobileNavList li:nth-child(1) {
  animation-delay: 0.1s;
}

.mobileNavList li:nth-child(2) {
  animation-delay: 0.2s;
}

.mobileNavList li:nth-child(3) {
  animation-delay: 0.3s;
}

.mobileNavList li:nth-child(4) {
  animation-delay: 0.4s;
}
.mobileNavList li:nth-child(5) {
  animation-delay: 0.5s;
} */

@keyframes slideUpIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobileNavList li:hover {
  transform: translateY(-5px);
  color: #1b00cebd;
}

.mobileNavList li a {
  text-decoration: none;
  color: inherit;
}

.mobileNavRight {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

@media (max-width: 938px) {
  .Navbar {
    padding: 5px 2%;
    padding-top: 10px;
    align-items: center;
  }

  .navList {
    display: none;
  }
}

@media (max-width: 480px) {
  .Navbar {
    padding: 10px 20px;
  }

  .logo {
    align-self: center;
  }

  .logo {
    height: 40px;
    width: 40px;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .navRight button {
    font-size: 11px;
    width: 100px;
    height: 35px;
  }

  .navRight {
    gap: 15px;
  }
}

.signupDropdown {
  /* position: relative; */
  display: inline-block;
}

/* .signupButton {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.signupButton:hover {
  background-color: #0056b3;
}

.dropdownIcon {
  transition: transform 0.3s ease;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  left: 0%;
  overflow: hidden;
  background-color: white;
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  min-width: 150px;
  z-index: 1000;
  animation: dropdownFadeIn 0.3s ease;
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdownItem {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.dropdownItem:hover {
  background-color: #b4b4b9;
  overflow: hidden;
  transform: scale(1.04);
}
